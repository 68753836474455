class Elementor_Image_Carousel{
    static instance;

    static getInstance() {
        if (!Elementor_Image_Carousel.instance) {
            Elementor_Image_Carousel.instance = new Elementor_Image_Carousel();
        }
        return Elementor_Image_Carousel.instance;
    }

    constructor(){
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init(){
        elementorFrontend.hooks.addAction( 'frontend/element_ready/image-carousel.default', ( $scope ) => {
            $(window).on("load", function () {
            let $buttonlink = $scope.find('.slick-arrow');
            $buttonlink.append('<span class="slick-arrow-link"></span>');
            });
        } );

    }
}

Elementor_Image_Carousel.getInstance();
