class Elementor_Schedules {
    static instance;

    static getInstance() {
        if (!Elementor_Schedules.instance) {
            Elementor_Schedules.instance = new Elementor_Schedules();
        }
        return Elementor_Schedules.instance;
    }

    constructor() {
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init() {
        elementorFrontend.hooks.addAction('frontend/element_ready/opal-schedules.default', ($scope) => {
            let $button = $scope.find('.item-schedules_items .button-schedules');
            $button.click(function () {
                $(this).parent().find('.description-schedules').slideToggle("500");
                $(this).toggleClass("active");
            });



            let $carousel = $scope.find('.owl-carousel');
            if ($carousel.length > 0) {
                let data = $carousel.data('settings');
                $carousel.owlCarousel(Elementor_Carousel.setupData(data));
            }

            // Active tab
            let $contenttab = $scope.find('.elementor-schedules-tab-content'),
                $tab = $scope.find('.elementor-schedules-tab');
            $contenttab.not('.elementor-schedules-tab-content-0').fadeOut();

            $tab.on('click', function () {
                let $active = $(this).data('tab');
                $contenttab.not($active).fadeOut(300);
                $tab.not($(this)).removeClass('elementor-active');
                $(this).addClass('elementor-active');
                setTimeout(function () {
                    $scope.find($active).fadeIn(300);
                }, 300);

            });

            $scope.find('.item-image').tooltipster({
                theme: 'opal-schedules-tooltipster',
                functionInit: function (instance, helper) {
                    var content = $(helper.origin).find(".tooltipster-speaker-content").detach();
                    instance.content(content);
                },
                delay: 0,
                animation: 'grow',
                // trigger: 'click'
            });


        });
    }
}


Elementor_Schedules.getInstance();