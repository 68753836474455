class Elementor_Speakers {
    static instance;

    static getInstance() {
        if (!Elementor_Speakers.instance) {
            Elementor_Speakers.instance = new Elementor_Speakers();
        }
        return Elementor_Speakers.instance;
    }

    constructor() {
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init() {
        elementorFrontend.hooks.addAction('frontend/element_ready/opal-speakers.default', ($scope) => {
            // let $container = $scope.find('.isotope-grid');
            let currentIsotope = $scope.find('.isotope-grid').isotope({filter: '*'});

            let widthbase = $scope.find('.column-item')[0].getBoundingClientRect().width;
            $scope.find('.elementor-speaker-heading').innerWidth(2*widthbase);

            currentIsotope.imagesLoaded(function () {
                currentIsotope.isotope('layout');
            });


        });
    }
}

Elementor_Speakers.getInstance();